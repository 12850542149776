.services__center{
    width: 80%;
    margin: auto;
    padding-top: 50px;
}


.services__row{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
   align-items: center;
}
.services__col1{
    flex-basis:60%;
    position: relative;
    overflow: hidden;

}
.services__col1 img{
    width: 100%;
    display: block;
    border-radius: 10px;
  
}


.services__col2{
    flex-basis:29%;
}
.services__col2 h2{
    font-size: 24px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
      
}

.services__col2 p{
    margin-top: 2%;
    font-size: 15px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
    font-weight: 700;

      
}

.services__col1 .layer{
    height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;
border-radius: 10px;
transition: 1s;
}
.services__col1 .layer:hover{
background: rgba(203, 226, 0, 0.7);

}
.services__col1 .layer h3{
width: 100%;
font-weight: 700;
color: #fff;
font-size: 26px;
bottom: 0;
left: 50%;
font-family: 'Nexa',sans-serif;

position: absolute;
transform: translateX(-10%);
opacity: 0;
transition: 0.5s;
}
.services__col1 .layer:hover h3{
bottom: 49%;
opacity: 1;
}
.touch{
    align-items: center;
    margin-right: 20%;
    margin-top: 10%;
    background: rgba(226, 229, 231, 1);
    width: 150px;
    color: #000000;
    padding: 5px;
    margin-left: 65%;
  border-radius: 5px;
display: none;  

}
.touch p{
 color: #000000;
 font-size: 20px;
 text-align: center;
 font-family: 'Nexa2',sans-serif;
 font-weight: 700;

}
#ico{
    font-size: 20px !important;
    color: rgb(19, 17, 17);
    font-weight: 600;
    margin-left: 2%;
    
 }

@media (max-width:1000px) {
    .services__row{
        flex-direction: column;
    }
    .services__col1 img{
        width: 100%;

        border-radius: 0px;
    }
    
    .services__col1 .layer{
      border-radius: 0px;
    
    }
    .services__col2 h2{
       margin-top: 25px;

          
    }
    .touch{
  display: block;
    }    

}
@media (max-width:500px) {
    .services__col1 .layer h3{
        font-size: 18px;
        bottom: 0;
        left: 50%;
        transform: translateX(-40px);
        
        }
        .touch{
            max-width: 150px;
            color: #000000;
            margin-left: 50%;
          border-radius: 5px;
        display: block;  
        
        }
        .touch p{
         color: #000000;
         font-size: 15px;
         text-align: center;
        }
        #ico{
            font-size: 15px !important;
            color: rgb(19, 17, 17);
            font-weight: 600;
            margin-left: 2%;
            
         }
}
@media (max-width:400px) {
        .touch{
            max-width: 100px;

        
        }
        .touch p{
         color: #000000;
         font-size: 12px;
         text-align: center;
        }
        #ico{
            font-size: 12px !important;
             
         }
}
@media (max-width:250px) {
    .touch{
        max-width: 80px;

    
    }
 
}