.row_m2ab{
    display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .col_m2ab{
        flex-basis:49%;
    
        padding: 5px;
        margin-top: 4%;
        margin-bottom: 3%;
    }
    .col_m2ab img{
        width: 100%;
    }
    
    .col_m2ab h2{
        font-family: Montserrat;
        font-size: 80px;
        font-weight: 400;
        line-height: 98px;
        letter-spacing: 0em;
        text-align: left;
   color: white;     
    }    
    .col_m2ab input{
width: 100%;
margin-top: 2%;
padding: 10px;
background: transparent;
border-color: white;
    }    
    .col_m2ab p{

font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: white;

    }
    
.col_m2ab button{
    margin-top: 2%;
    font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
color:white ;
width: 100%;
padding: 10px;
background: #FF007A;

}
.mapcon{
    width: 100%;
    height: 80vh;
}