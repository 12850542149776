.row_m2a{
    display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .col_m2a{
        flex-basis:46%;
    
        padding: 5px;
        margin-top: 4%;
        margin-bottom: 3%;
    }
    .col_m2a img{
        width: 100%;
    }
    
    .col_m2a h2{
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        
    }
    .col_m2a p{

font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: right;

    }
    
    @media (max-width:1110px) {
        .row_m2a{
    flex-direction: column;
        }
        .col__fir{
            margin-top: 10px;
        }
        .col__2a img{
            display: none;
        }
    }
    