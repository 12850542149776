
.home2__top{
    width: 80%;
    margin: auto;

}

.home2__top .div__one{
    position: absolute;
    background: #FFD200;
    border-radius: 5px;
    text-align: center;
    margin-left: 50%;
    z-index: 10;
    padding: 40px;
    margin-top: 10%;
    max-width: 250px;
   }

   
.div__one h2{
    margin-top: 5%;

    font-family: 'Nexa2',sans-serif;
     font-size: 45px;
     color: #333333;
     font-weight: 700;
 }
 .div__one p{
    font-family: 'Nexa2',sans-serif;
     font-size: 14px;
     color: #333333;
     font-weight: 700;
 }

.home2__top .div__second{
display: flex;
align-items:flex-start;
}
.home2__top h1{
    font-family: 'Nexa2',sans-serif;
    font-size: 72px;
    color: #333333;
        font-weight: 700;
    max-width: 500px;

}
.home2__top hr{
    margin-top: 4%;
    width: 50%;
    border: 2px solid #000000;
}
.home2__top img{
    width: 100%;
    margin-top: 5%;
}

.home2__mid{
    width: 80%;
    margin: auto;
    text-align: left;
    padding-top: 100px;
}
.home2__mid .mid__div__one{
    display: flex;
    align-items:center;
    
}
.home2__mid .mid__div__one h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    max-width: 500px;   
}
.home2__mid .mid__div__one hr{
   margin-left: 3%;
    width: 50%;
    border: 2px solid #000000;
    
}
.home2__mid .mid__div__two h2{
    margin-top: 2%;
    font-family: 'Nexa2',sans-serif;
    font-size: 30px;
    color: #333333;
    font-weight: 700;
    max-width: 1000px;   
}
.mid__div__two p{
    margin-top: 2%;
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #9C9C9C;
    font-weight: 700;
    max-width: 471px; 
}
.home2__mid img{
    width: 100%;
}
.home2__mid__card {
    position: absolute;
    background: #FFFFFF;
    width: 55%;
}
.mid__card__text{
display: flex;
max-width: 901px;
padding: 20px; 
align-items: center;
}
.mid__card__text h3{
    font-family: 'Nexa2',sans-serif;
    font-size: 24px;
    color: #333333;
    font-weight: 700;
}
.mid__card__text p{
    font-family: 'Nexa2',sans-serif;
    font-size: 24px;
    color: #333333;
    font-weight: 700;
    margin-left: 8%;
}
.mid__card__text i{
 font-size: 25px;
 margin-left: 40%;
}
.row{
    display: flex;
    justify-content: space-between;
}

.hom2__mide2{
    width: 80%;
    margin: auto;
    margin-top: 2%;
  
}
.person__profile{
    position: absolute;
    display: flex;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
margin-left: 50%;
margin-top: 2%;
width: 40%;
}
.profile__name{
    margin-left: 3%;
}
.profile__name h3{
    font-size: 18px;
    font-family: 'Nexa2',sans-serif;
    
    color: #000000;
    font-weight: 700;
      
}

.profile__name p{
    font-size: 16px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
    font-weight: 700;
      
}

.mide2__col1{
    flex-basis:70%;
    padding: 20px 12px;
    box-sizing: border-box;
    
   
}
.mide2__col1 img{
    width: 100%;
}
.mide2__col2{
    flex-basis:30%;
    padding: 20px 12px;
    box-sizing: border-box;
    
   
}
.mide2__col2 p{
    margin-left: 20%;
    margin-top: 35%;
    font-family: 'Nexa2',sans-serif;
   font-size: 18px;
    color: #9C9C9C;
    font-weight: 700;
    max-width: 180px;
 
}
.mide2__col2 button{
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px;
    margin-top: 3%;
    margin-left: 20%;
    border: 2px solid #FFD200;
    background-color: transparent;
}
.mide2__col2  button:hover{
    background-color: #FFD200;
    transition: 0.7s;

}

.hom2__mid__3{
    width: 80%;
    margin: auto;

}
.mid__3__one{
    align-items: center;
    display: flex;
    margin-top: 5%;
}
 .mid__3__one h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    max-width: 500px;   
}
 .mid__3__one hr{
   margin-left: 3%;
    width: 50%;
    border: 2px solid #000000;
    
}
.row__mid{
    display: flex;
    justify-content: space-between;
    
}
.mid__3__col{
    flex-basis:49%;
    margin-top: 4%;
  
}
.mid__3__col h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 36px;
    color: #333333;
        font-weight: 700;
    max-width: 500px; 
    line-height: 170%;  
}
.mid__3__col p{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #9C9C9C;
            font-weight: 700;
    max-width: 500px; 
    line-height: 160%;  
}

.mid__3a__col{
    flex-basis:10%;
    margin-top: 5%;
}
.mid__3a__col h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 32px;
    color: #333333;
    font-weight: 700;
}

.mid__3b__col{
    flex-basis:80%;
    margin-top: 5%;
}

.mid__3b__col h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 32px;
    color: #333333;
    font-weight: 700;
}
.mid__3b__col h3{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #333333;
    font-weight: 700;
    line-height: 160%;
    max-width: 500px;
    margin-top: 3%;
}

.mid__3c__col{
    flex-basis:10%;
    text-align: right;
    margin-top: 5%;

}

.mid__3c__col i{
    font-size: 24px;
}

.home2__material{
    width: 80%;
    margin: auto;
    margin-top: 5%;
}
.material__desc{
    display: flex;
    align-items: center;
}
.material__desc h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    max-width: 500px;   
}
.material__desc hr{
   margin-left: 3%;
    width: 50%;
    border: 2px solid #000000;
    
}

.home2__material h4{
    font-family: 'Nexa2',sans-serif;
    font-size: 36px;
    color: #333333;
    font-weight: 700;
    max-width: 348px;
    line-height: 170%;
}
.home2__material h3{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #9C9C9C;
        font-weight: 700;
    max-width: 420px;
    line-height: 160%;
    margin-top: 1%;
}

.home2__material button{
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 15px;
    margin-top: 3%;
    border-radius: 5px;
    border: 1px solid #3C3C3C;
        background-color: transparent;
}
.home2__material  button:hover{
    background-color: #FFD200;
    transition: 0.7s;
    border: 1px solid #FFD200;

}
.material__pic2{
    max-width: 12%;
    position: absolute;
    margin-left: 65%;
}
.material__pic1{
    max-width: 18%;
    margin-top: 4%;
    position: absolute;
    margin-left: 45%;
}
.material__pic3{
    max-width: 10%;
    margin-top: 10.5%;
    position: absolute;
    margin-left: 65%;
}
.material__pic3c,.material__pic2b,.material__pic1a{
    display: none;
}

.last__banner{
    background: #FFD200;
    width: 100%;
    margin-top: 8%;
    margin-bottom: 5%;
}
.last__banner__width{
    width: 80%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}
.last__banner__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.last__banner__width__col{
    flex-basis:60%;

}
.last__banner__width__col h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 36px;
    color: #000000;
        font-weight: 700;
    max-width: 580px;
    line-height: 170%;

}
.last__banner__width__colb{
    flex-basis:40%;

}
.last__banner__width__colb h2{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #000000;
        font-weight: 700;
    max-width: 420px;
    line-height: 170%;
 
}
.last__banner__width__colb a{

    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 15px;
    margin-top: 3%;

    border-radius: 5px;
    border: 1px solid #3C3C3C;
        background-color: #FFFFFF;
}
.last__banner__width__colb  a:hover{
    background-color: black;
    transition: 0.7s;
    color: #FFFFFF;
    border: 1px solid black;

}
@media (max-width:1000px) {
    .last__banner__row{
        flex-direction: column;
        align-items: baseline;
    }
    .home2__mid__card {
        position: relative;
        background: #FFFFFF;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .row{
        flex-direction: column;
    }
    .home2__mid{
        padding-top: 50px;
   }
   .home2__top h1{
       font-size: 32px;
      margin-top: 5%;  
   }
   .home2__top hr{
       margin-top: 8%;
       }
       .home2__top .div__one{
           margin-left: 55%;
           padding: 20px;
           margin-top: 10%;
           max-width: 200px;
          }
       
          
       .div__one h2{
           margin-top: 5%;
       
           font-family: 'Nexa2',sans-serif;
            font-size: 25px;
         }
        .div__one p{
            font-size: 8px;
        }
        .mide2__col2 p{
           margin-left: 0%;
           margin-top: 2%;
           font-family: 'Nexa2',sans-serif;
          font-size: 18px;
           max-width: 480px;
        
       }
       .mide2__col2 button{
           font-size: 14px;
           margin-top: 25px;
           margin-left: 0%;
         
       }
    .material__pic2b{
        max-width: 25%;
display: block;
        margin-left: 45%;
        position: absolute;

    }
    .material__pic1a{
        max-width: 100%;
        margin-top: 4%;
        position: relative;
        margin-left: 0%;
        display: block;
    }
    .material__pic3c{
        max-width: 20%;
        margin-top: 25%;
        position: absolute;
        margin-left: 45%;
        display: block;
    }
    .material__pic3,.material__pic2,.material__pic1{
        display: none;
    }
    .row__mid{
        flex-direction: column;
    }
    .mid__3__col h2{
        font-size: 26px;

        max-width: 400px; 
    }
}
@media (max-width:780px) {
 
    .home2__mid{
         padding-top: 50px;
    }
    .home2__top h1{
        font-size: 32px;
       margin-top: 5%;  
    }
    .home2__top hr{
        margin-top: 8%;
        }
        .home2__top .div__one{
            margin-left: 55%;
            padding: 20px;
            margin-top: 10%;
            max-width: 200px;
           }
        
           
        .div__one h2{
            margin-top: 5%;
        
            font-family: 'Nexa2',sans-serif;
             font-size: 25px;
          }
         .div__one p{
             font-size: 8px;
         }
         .mide2__col2 p{
            margin-left: 0%;
            margin-top: 2%;
            font-family: 'Nexa2',sans-serif;
           font-size: 18px;
            max-width: 480px;
         
        }
        .mide2__col2 button{
            font-size: 14px;
            margin-top: 25px;
            margin-left: 0%;
          
        }
            .material__pic1a{
        max-width: 50%;
            }
        
}
@media (max-width:650px) {
    .mid__3a__col{

        margin-top: 15px;
    }
    .mid__3a__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 26px;
    }
    
    .mid__3b__col{

        margin-top: 15px;
    }
    
    .mid__3b__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 24px;
        margin-left: 5px;
    }
    .mid__3b__col h3{
        font-family: 'Nexa2',sans-serif;
        font-size: 14px;

        max-width: 500px;
        margin-top: 15px;
        margin-left: 5px;
    }
    
    .mid__3c__col{

        margin-top: 15px;
    
    }
    
    .mid__3c__col i{
        font-size: 16px;
    }

}
@media (max-width:510px) {
    .home2__top img{
        width: 100%;
        margin-top: 5%;
        height: 20vh;
    }
         .home2__top .div__one{
            margin-left: 56%;
            padding: 15px;
            margin-top: 20%;
            max-width: 200px;
           }
        
           
        .div__one h2{
            margin-top: 5%;
        
            font-family: 'Nexa2',sans-serif;
             font-size: 18px;
          }
         .div__one p{
             font-size: 5px;
         }
         .home2__mid .mid__div__two h2{
            font-size: 16px;
         }
         .person__profile{
            padding: 20px;
        margin-left: 40%;
        margin-top: 2%;
        width: 35%;
        }
        .profile__name{
            margin-left: 4%;
        }
        .home2__mid img{
            height: 30vh;
        }
        .mid__3a__col h2{
            font-family: 'Nexa2',sans-serif;
            font-size: 22px;
        }
        
        
        .mid__3b__col h2{
            font-family: 'Nexa2',sans-serif;
            font-size: 22px;
        }
        .mid__3b__col h3{
            font-size: 12px;
    
        }
        
        
        .mid__3c__col i{
            font-size: 13px;
        }
    
}
@media (max-width:370px) {
    .mide2__col1 img{
        height: 30vh;
    }
    .person__profile{
        padding: 20px;
    margin-left: 30%;
    margin-top: 2%;
    width: 50%;
    }
    .profile__name{
        margin-left: 4%;
    }
    .profile__name h3{
        font-size: 18px;
          
    }
    
    .profile__name p{
        font-size: 16px;
            
    }  
    .mid__3a__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 17px;
    }
    
    
    .mid__3b__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 17px;
    }
    .mid__3b__col h3{
        font-size: 10px;

    }
    
    
    .mid__3c__col i{
        font-size: 10px;
    }
    .mid__3__col h2{
        font-size: 20px;

        max-width: 400px; 
    }
    .last__banner__width__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 26px;}
}
@media (max-width:300px) {
   
         .home2__top .div__one{
            margin-left: 56%;
            padding: 15px;
            margin-top: 30%;
            max-width: 200px;
           }
           .person__profile{
            padding: 20px;
        margin-left: 20%;
        margin-top: 2%;
        width: 60%;
        }
        .profile__name{
            margin-left: 4%;
        }
        .profile__name h3{
            font-size: 13px;
              
        }
        
        .profile__name p{
            font-size: 10px;
                
        }
        
}
@media (max-width:250px) {
   
    .home2__top .div__one{
       margin-left: 56%;
       padding: 15px;
       margin-top: 60%;
       max-width: 200px;
      }
      .last__banner__width__col h2{
        font-family: 'Nexa2',sans-serif;
        font-size: 18px;}  
}