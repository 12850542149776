@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Kumbh+Sans:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.row_m2ac{
    display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .col_m2ac{
        flex-basis:31%;
    
        padding: 5px;
        margin-top: 4%;
        margin-bottom: 3%;
    }
    .col_m2ac img{
        width: 100%;
    }
    
    .col_m2ac h2{
        font-family: Work Sans;
        font-size: 27px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;
        
        
    }
    .col_m2ac p{
     
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        

    }
    @media (max-width:1110px) {
        .row_m2ac{
    flex-direction: column;
        }
        .col__fir{
            margin-top: 10px;
        }
     }
    
    