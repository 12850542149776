.footer__section{
    width: 100%;
    background: #000000;

}
.footer___width{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

}

.footer___width img{
   

width: 20%;
}
.footer__width ul{
    display: flex ;
    text-align: center;

    
}

.footer__width ul li{
    padding: 8px;
    margin-top: 2%;
}
.footer___width  a{
    font-family: Montserrat;
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
   color:white;
   padding: 10px;    
}
.footer__width ul li a:hover{
    color: #584f4f;
}
@media (max-width:1200px) {
    .a{
        flex-direction: column;
      
    }
}
