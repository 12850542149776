@font-face {
    font-family: Nexa;
    src: url("./nexabold/Nexa\ Bold.otf");
  }
  @font-face {
    font-family: Nexa2;
    src: url("./nexabold/Nexa\ Light.otf");
  }
  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Kumbh+Sans:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); 
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Kumbh+Sans:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
    margin: 0;
    padding: 0;
}
body{
    
    overflow-X: hidden;
}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
.Top__up{
color: #000000;
position: fixed;
margin-top: 80vh;
background-color: #FFD200;
margin-left: 90%;
}
.header_top{
    height: 100vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.8), rgba(15, 14, 14, 0.8)),url(./4a7a73dd-771b-4e67-89e9-23fa210f7e13-min.jpeg);
  background-position:center ;
    background-size: cover;
    opacity: 1;
 
}
.header_topb{
    height: 100vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.7), rgba(15, 14, 14, 0.7)),url(./4a7a73dd-771b-4e67-89e9-23fa210f7e13-min.jpeg);
  background-position:center ;
    background-size: cover;
    opacity: 1;
 
}
.top__m{
    width: 80%;
    margin: auto;
}
.top__m h1{
    font-family: Montserrat;
font-size: 66px;
font-weight: 400;
line-height: 100px;
letter-spacing: 0em;
text-align: left;
padding-top: 200px;

color: #FF007A;
}
.top__m h2{
    font-family: Montserrat;
font-size: 66px;
line-height: 100px;
letter-spacing: 0em;
text-align: left;
color: white;
}
.top__m p{
font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:white;
max-width: 500px;
}
.top__m button{
    border: 1px solid #FF007A;
    font-family: Montserrat;
font-size: 21px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-top: 2%;
background: transparent;
color: #FF007A;
padding: 10px;
}
.roww{
    display: flex ;
    margin-top: 2%;
}
.colll{
    flex-basis:20%;

}

.roww h2{
    font-family: Montserrat;
font-size: 40px;
font-weight: 400;
line-height: 56px;
letter-spacing: 0em;
margin-left: 22%;

}
.roww p{

font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}

.main__middle{
    background: #121212;

}
.main__middle2{
    width: 80%;
    margin: auto;
}
.main__middle2 h2{
    padding-top: 8%;
    font-family: Montserrat;
font-size: 80px;
font-weight: 400;
line-height: 96px;
letter-spacing: 0em;
text-align: center;
color: white;

}
#idtext{

color: #FF007A;
}
.main__middle2 p{
color: #fff;
font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: center;

} 
.row__fir{

    margin-top: 2%;
    display: flex;
    justify-content: space-between;
}
.col__fir{
    flex-basis:49%;
    border: 1px solid #FF007A;
    
    padding: 30px;
    
}
.col__fir h2{

font-family: Bebas Neue;
font-size: 38px;
font-weight: 700;
line-height: 48px;
letter-spacing: 0em;
text-align: left;


}
.col__fir h3{
    margin-top: 6%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    
    color: #FF007A;
}
.col__fir p{

    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}
.middle__main_2{
    background-image: linear-gradient(rgba(15, 14, 14, 0.9), rgba(15, 14, 14, 1)),url(./bgbxmid-min.png);
  background-position:center ;
    background-size: cover;
    opacity: 1;
    padding-top: 30px;
    padding-bottom: 30px;
}
.middle__main_2a{
margin: auto;
width: 80%;

}

.row_m2{
display: flex;
    justify-content: space-between;
    align-items: center;
}
.col_m2{
    flex-basis:46%;

    padding: 5px;
    margin-top: 5%;
    margin-bottom: 3%;
}
.col_m2 img{
    width: 100%;
}

.col_m2 h2{
    font-family: Montserrat;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
color: white;    
}
#id_c4{
    color: #FF007A;
    font-family: Bebas Neue;
    font-size: 80px;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;

}
#id_c3{
    color: #FF007A;
}
.col_m2 p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
color: white;    

}
.col_m2 button{
    border: 1px solid #FF007A;
    font-family: Montserrat;
font-size: 21px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-top: 4%;
background: transparent;
color: #FF007A;
padding: 10px;
}
.main__middle4{
    width: 80%;
    margin: auto;
}
.main__middle4 h2{
    font-family: Montserrat
    ;
    font-size: 61px;
    font-weight: 500;
    line-height: 76px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
} 
.main__middle4 p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
color: white;       
}
.main__middleddd{
    height: 100vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.9), rgba(15, 14, 14, 0.9)),url(./img111-min.png);
  background-position:center ;
    background-size: cover;
    opacity: 1;
 
}
.main__middle5{
    width: 80%;
    margin: auto;
}

.main__middle5 h2{
    font-family: Montserrat
    ;
    font-size: 61px;
    font-weight: 500;
    line-height: 76px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}
.main__middle5 p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
color: white;       
}
.main__middle7{
 padding-top: 30px;
 padding-bottom: 30px;
       background: #121212;
       display: none;
}

.main__middle8{
    width: 80%;
margin: auto;
    
}
.main__middle8 h2{
    font-family: Montserrat;
font-size: 60px;
font-weight: 400;
line-height: 76px;
letter-spacing: 0em;
text-align: left;

color: white;

}
.main__middle8 p{
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 400;

    letter-spacing: 0em;
    text-align: left;
    
    color: white;
     
}
.main__middle8 input{
    width: 100%;
    padding: 10px;
    background: transparent;
    border: 1px solid #FFFFFF
}
.main__middle6{
    width: 100%;
 padding-top: 70px;
    background: #121212;
    padding-bottom: 50px;
    

}
.las__sec{
    background-image: url(./Group\ 5-min\ \(1\).png);
width: 80vh;
 margin: auto; 
 background-position:center ;
 background-size: cover;
 height: 100vh;
display: block; 


}
.main__middle8 button{
    border: 1px solid #FF007A;
    font-family: Montserrat;
font-size: 21px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-top: 3%;
background: transparent;
color: #FF007A;
padding: 10px;
}
.last_section{
  }
.form__main{
margin-left: 45%;
padding-top: 22vh ;
}
.form__main h2{
    font-family: Montserrat;
font-size: 60px;
font-weight: 400;
line-height: 76px;
letter-spacing: 0em;
text-align: left;

color: white;
}
.row__3{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.col__2a{
    flex-basis:40%;
    text-align: left;
     margin-top: 2%
}
.col__2b{
    flex-basis:55%;
    text-align: left;
     margin-top: 2%

}
.col__2a img{
    width:85%;
    margin-left: 10%;
}
.col__2b h2{
    font-family: Montserrat;
    font-size: 60px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    

}
.col__2b p{

font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #F1F1F1
;
max-width: 440px;
}

.row__input{
    display: flex;

}

.col_input{
    flex-basis:47%;
    text-align: left;
     margin-top: 2%
}
.col_input input{
  
    background: transparent;
    border: 1px solid #FFFFFF;
    padding: 10px;
    color: white;
    font-family: Poppins;
}
.form__main input{
    background: transparent;
    border: 1px solid #FFFFFF;
    padding: 10px;

}
.form__main button{
    border: 1px solid #FF007A;
    font-family: Montserrat;
font-size: 21px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-top: 3%;
background: transparent;
color: #FF007A;
padding: 10px;
}
.col__2b button{

    font-family: Montserrat;

font-size: 21px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
background: transparent;
border: 1px solid  #FF007A
;
color:#FF007A;
margin-top: 2%;
padding: 15px;
}

@media (max-width:2000px) {
   
        .las__sec{
    background-image: url(./Group\ 5-min\ \(1\).png);
width: 80%;
 margin: auto; 
 background-position:center ;
 background-size: cover;
 height: 100vh;


}
.last_section{
  }

.form__main h2{
    font-family: Montserrat;
font-size: 60px;
font-weight: 400;
line-height: 76px;
letter-spacing: 0em;
text-align: left;

color: white;
}
}

@media (max-width:1350px) {
    .main__middle6{
display: none;
    }
        .main__middle7 {
            display: block;
        }

}
@media (max-width:1110px) {
    .roww,.row__fir,.row_m2,.row__3{
flex-direction: column;
    }
    .col__fir{
        margin-top: 10px;
    }
    .col__2a img{
        display: none;
    }
}

@media (max-width:600px) {
    .top__m h1{
        font-family: Montserrat;
    font-size: 66px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 190px;

    }
    .top__m h2{
        font-family: Bebas Neue;
    font-size: 66px;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF007A;
    
    }
    .top__m p{
        font-size: 20px;
    }
    .top__m button{
        margin-top: 20px;
    }
    .main__middle2 h2{
        font-size: 40px;
        line-height: 50px;
    }
}

