.about__us{
    width: 80%;
    margin: auto;
 text-align: center;
 padding-top: 50px;
}
.about__us h1{
    font-family: 'Nexa-Bold';
    font-size: 56px;
    color: #000000;
    font-weight: 500;
    text-align: center;
    line-height: 140%;

}
.about__us h2{
    text-align: center;
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #000000;
    font-weight: 700;

line-height: 160%;
margin-top: 3%;

}
.about__us img{
    margin-top: 25px;
    width: 100%;
}
.about__achievment{
    width: 80%;
    margin: auto;
    padding-top: 100px;
}

.achive__a{
    display: flex;
    align-items: center;
}
.achive__a h2{
 font-size: 16px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
}

.achive__a hr{
width: 50%;
border: 2px solid #000000;
margin-left: 2%;
}
.about__achievment h3{
font-size: 36px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
    max-width: 1200px;
   margin-top: 3%;
}
.achivment__row{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
 
}
.achivment__col1{
    flex-basis:67%;
    
}
.achivment__col1 img{
    width: 100%;
}
.achivment__col2{
    flex-basis:28%;
    
}
.achivment__col2 div{
    
    background: #FFD200;
    border-radius: 5px;
    text-align: center;
     padding: 40px;
     width: 100%;
     
}
.achivment__col2 div h2{
    margin-top: 5.5%;
    margin-bottom: 6%;
    font-family: 'Nexa2',sans-serif;
     font-size: 45px;
     color: #333333;
     font-weight: 700;
 }
 .achivment__col2 div p{
    font-family: 'Nexa2',sans-serif;
     font-size: 14px;
     color: #333333;
     font-weight: 700;
 }

 .about__service{
    width: 80%;
    margin: auto;
    padding-top: 100px;
}

#about__h3{
    font-size: 36px;
        font-family: 'Nexa2',sans-serif;
        color: #000000;
        font-weight: 700;
        max-width: 738px;
       margin-top: 3%;
}
.about__service h4{
    font-size: 18px;
        font-family: 'Nexa2',sans-serif;
        color: #9C9C9C;
        font-weight: 700;
        max-width: 1200px;
        margin-top: 3%;
       line-height: 160%;

}
.service__row{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    
}
.service__col{
    flex-basis: 31%;
}
.service__bx{
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
   transition: 0.5s;
   color: #9C9C9C;

}

.service__bx img{
    width:20%;
    
}
#imgcircle{
    position: absolute;
    z-index: 2;
    width: 7%;
    margin-top: 5.2%;
    margin-left: 5.4%;
    
}
#bx__h2{
    font-size: 24px;
        font-family: 'Nexa2',sans-serif;
        color: #333333;
        font-weight: 700;
      margin-top: 5%;

}
#bx__h3{
    font-size: 14px;
        font-family: 'Nexa2',sans-serif;
        font-weight: 700;
        line-height: 160%;
        color: #000000;
        margin-top: 5px;

}

@media (max-width:1300px){
    .achivment__col2 div{
    
         padding: 30px;
         height: 100%;
         
    }
    .achivment__col2 div h2{
         font-size: 25px;
         color: #333333;
     }
     .achivment__col2 div p{
         font-size: 12px;
     }
       
}
@media (max-width:950px){
    .achivment__row{
        flex-direction: column;
      
    }
    .achivment__col2 div{
      
        margin-top: 40px;
         width: 50%;     
         padding: 25px;
         height: 100%;

       margin-left: 25%;       
    }
    .achivment__col2 div h2{
         font-size: 30px;
         color: #333333;
     }
     .achivment__col2 div p{
         font-size: 18px;
     }
     .service__row{
        flex-direction: column;

     }
     .service__col{
         margin-top: 25px;
     }
     .service__bx img{
        width: 22%;
    }
    #imgcircle{
        width: 10%;
        margin-top: 5%;
        margin-left: 5.5%;
        
    }
}
@media (max-width:400px){
    .service__bx img{
        width: 35%;
    }
    #imgcircle{
        width: 13%;
        margin-top: 8%;
        margin-left: 8.4%;
        
    }
    .achivment__col2 div h2{
        font-size: 18px;
    }
    .achivment__col2 div p{
        font-size: 10px;
    }
    .about__achievment h3{
        font-size: 24px;}
        .about__us h1{
            font-size: 36px;
          
        }
}