@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 70px;
  background:transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
  font-family: 'Poppins', sans-serif;
   
}
nav .navbar{
  height: 100%;
  max-width: 1250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  /* background: red; */
  padding: 0 50px;
}
.navbar .logo a{
  font-size: 30px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;

}
nav .navbar .nav-links{
  line-height: 70px;
  height: 100%;
}
nav .navbar .links{
  display: flex;
}
nav .navbar .links li{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 35px;
}
nav .navbar .links li a{
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: white;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;

} 
nav .navbar .links li a:hover{
  color:#FF007A;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  transform: rotate(0deg);
  }

nav .navbar .links li .arrow{
  /* background: red; */
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: var(--primary-color);
  transition: all 0.3s ease;
}
nav .navbar .links li .sub-menu{
  position: absolute;
  top: 70px;
  left: -35px;

  line-height: 40px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
 
  z-index: 2;
  text-align: left;
}
nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu{
  display: block;
}
.navbar .links li .sub-menu li{
  padding: 0 20px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
 margin-left: -32px;
}
.navbar .links li .sub-menu a{
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  width: 100%;
}
.navbar .links li .sub-menu  li:hover{
  background: #FFD200;
  
}
.navbar .links li .sub-menu .more-arrow{
  line-height: 40px;
}
.navbar .links li .htmlCss-more-sub-menu{
  /* line-height: 40px; */
}
.navbar .links li .sub-menu .more-sub-menu{
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu{
  display: block;
}

.navbar .nav-links .sidebar-logo{
  display: none;
}
.navbar .bx-menu{
  display: none;
}
.sticky__header{
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px #ddd;
  

}
@media (max-width:1000px) {
  nav .navbar .links li{

    padding: 0 25px;
  }
}

@media (max-width:920px) {
  nav .navbar{
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a{
    font-size: 27px;
  }
  nav .navbar .links li{
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a{
    font-size: 15px;
  }
}
@media (max-width:800px){
  nav{
    /* position: relative; */
  }
  .navbar .bx-menu{
    display: block;
  }
  nav .navbar .nav-links{
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
transition: all 0.5s ease;
    z-index: 1000;


  }

  .navbar .nav-links .sidebar-logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name{
    font-size: 14px;
    color: var(--primary-color);    margin-left: 15px;
    display: none;
  }
  .navbar .links li .sub-menu  li:hover{
    
  }
  .navbar .links li .sub-menu a{
    color: var(--primary-color);
        } 
           .sidebar-logo  i,
    .navbar .bx-menu{
      font-size: 25px;
      color: var(--primary-color);   }
  nav .navbar .links{
    display: block;
    margin-top: 70px;
  }
  .navbar .links li .sub-menu  li:hover{
    background-color: #fff;
    background-color: #fff;
    
  }
  nav .navbar .links li .arrow{
    line-height: 40px;
    
  }
nav .navbar .links li{
    display: block;
  }
nav .navbar .links li .sub-menu{
  position: relative;
  top: 0;
  box-shadow: none;
  display: none;

  background-color: rgba(0,0,0, 0);
  
}
nav .navbar .links li a{
  color: var(--primary-color);
} 
nav .navbar .links li a:hover{
  color: var(--primary-color);}
nav .navbar .links li .sub-menu li{
  border-bottom: none;

}
.navbar .links li .sub-menu li{
 
 margin-left: 0px;
}
.navbar .links li .sub-menu .more-sub-menu{
  display: none;
  position: relative;
  left: 0;
}
.navbar .links li .sub-menu .more-sub-menu li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  transform: rotate(0deg);
  color: var(--primary-color);
  }
  .navbar .links li .sub-menu .more-sub-menu{
    display: none;
  }
  .navbar .links li .sub-menu .more span{
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu{
    display: none;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu{
    display: none;
  }
.navbar .nav-links.show1 .links .htmlCss-sub-menu,
  .navbar .nav-links.show3 .links .js-sub-menu,
  .navbar .nav-links.show2 .links .more .more-sub-menu{
      display: block;
    }
    .navbar .nav-links.show1 .links .htmlcss-arrow,
    .navbar .nav-links.show3 .links .js-arrow{
        transform: rotate(0deg);
}
.navbar .logo img{
  width: 100%;
}

}
@media (max-width:370px){
  nav .navbar .nav-links{
  max-width: 100%;
}
.navbar .logo img{
  width: 70%;
}
nav .navbar .links li .sub-menu{
margin-left: -5px;

}
nav .navbar .links li .sub-menu  li a{
  font-size: 12px;
  max-width: 20px;
}

}
@media (max-width:330px){
  nav .navbar .nav-links{
    width: 200px;
 
  }
}
@media (max-width:300px){

.navbar .logo img{
  width: 40%;
  margin-left: 45%;
} 
}
@media (max-width:250px){

  .navbar .logo img{
    width: 0%;
    margin-left: 45%;
  } 
  }



  @media (max-width:270px){
    nav .navbar .nav-links{
      max-width: 150px;
  
    }
  
    nav .navbar .links li .sub-menu li{
  margin-left: -9px;
  display: none;
  
  }
  nav .navbar .links li .arrow{
    line-height: 40px;
   display: none; 
  }
  
  nav .navbar .links li .sub-menu  li a{
    font-size: 12px;
    max-width: 20px;
  }
  
  }

  @media (max-width:230px){
    nav .navbar .nav-links{
      max-width: 120px;
  
    }
    nav .navbar .links li{
      text-align: left;
      margin-left: -10px;
 
    }
    nav .navbar .links li a{
      font-size: 12px;
      text-align: left;
    }
  }