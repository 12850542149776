.structural__center{
    width: 80%;
    margin: auto;
    margin-top: 5%;
 }
 .structural__center h4{
    font-size: 32px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
 text-align: center;
 }
.structural__center img{
width: 100%;
}
.struct__row{
    display: flex;
    justify-content: space-between;
    
}
.struct__col1{
    flex-basis:49%;
}
.struct__col1 h2{
    font-size: 24px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
      
}
.structural__center p{
    margin-top: 2%;
    font-size: 15px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
    font-weight: 700;
}


.struct__col1 p{
    margin-top: 2%;
    font-size: 15px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
    font-weight: 700;
max-width: 584px;
  
}
.struct__col1 span{
    font-size: 15px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
    font-weight: 700;
  
}
.struct__col1 img{
    width: 100%;
}

@media (max-width:780px) {
    .struct__row{
        flex-direction: column;
    } 
    .struct__col1{
        margin-top: 20px;
    }  
}