.ourteam__center{
    width: 80%;
    margin: auto;
    padding-top: 50px;
}

.ourteam__row{
    display: flex ;
    justify-content: space-between;
    margin-top: 2%;
    

}
.ourteam__col1{
    flex-basis:24%;
   margin-bottom: 25px;
}
.ourteam__col1 img{
    width: 100%;
    position: relative;
}
.ourteam__col1 div{
    position: absolute;
    background: #FFFFFF;  z-index: 99;
    margin-top: 19%;
    padding: 14px;
    margin-left: .4%;
    width: 17%;
}
.ourteam__col1 div h3{
    font-size: 20px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
    font-weight: 700;
    text-align: center;
}
.ourteam__col1 div h4{
    font-size: 14px;
    font-family: 'Nexa2',sans-serif;
    color: #9C9C9C;
        font-weight: 700;
    margin-top: 2%;
    text-align: center;
}
.team__details{
    width: 80%;
    margin: auto;
    padding-top: 50px;
}
.team__details h2{
    font-size: 25px;
    font-family: 'Nexa',sans-serif;
    color: #000000;
    font-weight: 500;
    text-align: left;
}
.team__details h3{
    font-size: 20px;
    font-family: 'Nexa2',sans-serif;
    color: #000000;
        font-weight: 700;
    margin-top: 15px;
    text-align: left;
}
.team__details p{
    font-size: 15px;
    font-family: 'Nexa2',sans-serif;
    color: #2e2b2b;
   font-weight: 700;
  margin-top: 15px;
    text-align: left;
}

.team__details a{
    color: #000000;
    font-weight: 700;
    font-family: 'Nexa2',sans-serif;
    font-size: 16px;
 }
@media (max-width:1200px) {
    .ourteam__col1{
        flex-basis:49%;
        margin-bottom: 35px;
    }
    .ourteam__col1 div{
        margin-top: 40.5%;
        padding: 15px;
        margin-left: 6%;
        width: 25%;
    }
    .ourteam__col1 div h3{
        font-size: 20px;
        font-family: 'Nexa2',sans-serif;
        color: #000000;
        font-weight: 700;
        text-align: center;
    }
    .ourteam__col1 div h4{
        font-size: 14px;
        font-family: 'Nexa2',sans-serif;
        color: #9C9C9C;
            font-weight: 700;
        margin-top: 2%;
        text-align: center;
    }
}
@media (max-width:1000px) {
    
    .ourteam__col1 div{
        margin-top: 90%;
        padding: 15px;
        margin-left: 15%;
        width: 50%;
    }
    .ourteam__col1 div h3{
        font-size: 24px;

    }
    .ourteam__col1 div h4{
        font-size: 16px;

    }
}

@media (max-width:410px) {
    
    .ourteam__col1 div{
        margin-top: 80%;
        padding: 15px;
        margin-left: 9%;
        width: 60%;
    }
    .ourteam__col1 div h3{
        font-size: 20px;

    }
    .ourteam__col1 div h4{
        font-size: 14px;

    }
}

@media (max-width:300px) {
    
    .ourteam__col1 div{
        margin-top: 80%;
        padding: 15px;
        margin-left: 9%;
        width: 60%;
    }
    .ourteam__col1 div h3{
        font-size: 15px;

    }
    .ourteam__col1 div h4{
        font-size: 12px;

    }
}